import { LitElement, render, html, css } from "lit";

export default class ScrollContainer extends LitElement {
    static get is() { return 'scroll-container'; }
    static styles = css`
        :host {
            display: block;
            height: 100svh; height: 100%;
            overflow-y: hidden;
            background: black;
        }
        
        ::slotted(.section) {
            position: absolute;
            width: 100%;
            height: 100svh; height: 100%;
            transform: translateY(-100%);
            transition: transform 250ms ease-in-out;
            overflow: hidden;
        }

        ::slotted(.section.active) {
            transform: translateY(0%);
        }

        ::slotted(.section.hidden) {
            transform: translateY(100%);
        }
    `;

    connectedCallback() {
        super.connectedCallback();

        this.lastValues = [];
        this.timeoutId; this.lastTimestamp; this.lastTriggerTimestamp;
        const wheelHandler = (e) => {
            if (this.lastTimestamp !== undefined && this.lastTriggerTimestamp !== undefined) {
                const deltaTriggerTimestamp = e.timeStamp - this.lastTriggerTimestamp;
                const deltaTimestamp = e.timeStamp - this.lastTimestamp;

                const absDeltaY = Math.abs(e.deltaY);
                const isTrendChanging = this.lastValues.every((val) => absDeltaY - val > 10) && deltaTriggerTimestamp > 250;
                if (deltaTimestamp < 250 && !isTrendChanging) {
                    e.stopImmediatePropagation();

                    clearTimeout(this.timeoutId);
                    this.timeoutId = setTimeout(() => {
                        this.lastValues = [];
                    }, 250);

                    this.lastTimestamp = e.timeStamp;

                    this.lastValues.push(absDeltaY);
                    if (this.lastValues.length > 10)
                        this.lastValues.shift();

                    // console.log("ignore: ", e.deltaY);
                    return;
                }
            }

            this.lastTriggerTimestamp = e.timeStamp;
            this.lastTimestamp = e.timeStamp;
        }

        this.addEventListener("wheel", wheelHandler, { capture: true, passive: true });
    }

    render() {
        console.debug("scroll-container: render");
        return html`<slot @slotchange=${this.handleSlotchange}></slot>`;
    }

    handleSlotchange(e) {
        console.debug("handleSlotChange");
        const sections = e.target.assignedElements();
        sections.forEach((section, i) => {
            section.style.zIndex = (sections.length - 1) - i;

            if (i > 0)
                section.classList.add("hidden");
        });
    }

    getFirstSection() {
        const slot = this.shadowRoot.querySelector('slot');
        const sections = slot.assignedElements();
        return sections.reduce(function (a, b) { return a.style.zIndex > b.style.zIndex ? a : b });
    }

    getNextSection(section, direction) {
        const slot = this.shadowRoot.querySelector('slot');
        const targetZIndex = Number(section.style.zIndex) - direction;
        const sections = slot.assignedElements();
        return sections.find(s => { return Number(s.style.zIndex) === targetZIndex; });
    };

    shift() {
        const firstSection = this.getFirstSection();
        firstSection.style.zIndex = -1;

        const slot = this.shadowRoot.querySelector('slot');
        const sections = slot.assignedElements();

        const activeZIndex = Number(this.activeSection.style.zIndex);
        sections.forEach((section) => {
            section.style.zIndex++;

            if (Number(section.style.zIndex) <= activeZIndex)
                section.classList.add("hidden");
        });
    }

    createSection(innerHtml) {
        var section = document.createElement('div');
        section.className = 'section';
        render(innerHtml, section);

        section.addEventListener("wheel", async (e) => {
            const direction = Math.sign(e.deltaY);
            this.scrollToSection(section, direction);
        }, { passive: true });

        if (this.firstSectionReady === undefined) {
            this.firstSectionReady = true;
            section.classList.add("active");
            this.activeSection = section;
        }

        return section;
    }

    scrollToNextSection(direction) {
        this.scrollToSection(this.activeSection, direction);
    }

    scrollToSection(section, direction) {
        const nextSection = this.getNextSection(section, direction);
        if (nextSection === undefined || nextSection === null) {
            if (direction < 0)
                section.animate(
                    [{ transform: "translateY(0)" }, { transform: "translateY(10%)" }, { transform: "translateY(0)" }],
                    { duration: 250, easing: "ease-out" },
                );

            return;
        }

        if (this.timeout !== undefined) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }

        const createSectionEvent = (name) => new CustomEvent(name, {
            detail: {
                current: section,
                target: nextSection,
                direction: direction
            }, bubbles: true, composed: true
        });

        section.addEventListener("transitionstart", () => this.dispatchEvent(createSectionEvent("sectionleave")), { once: true });
        section.addEventListener("transitionend", () => this.dispatchEvent(createSectionEvent("sectionenter")), { once: true });

        section.classList.remove("active");
        nextSection.classList.add("active");

        if (direction > 0)
            nextSection.classList.remove("hidden");
        else
            section.classList.add("hidden");

        this.activeSection = nextSection;
    }
}

customElements.define(ScrollContainer.is, ScrollContainer);