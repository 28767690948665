import { LitElement, html } from 'lit';
import { Task } from '@lit/task';

import Plyr from 'plyr';
import PlyrStyles from 'plyr/dist/plyr.css';
import PlyrCustomStyles from '../../css/plyr_custom.css';

import LoadingAnimation from './loading-animation';
import "../logo";
import "../icons";

export default class VideoPlayer extends LitElement {
    static get is() { return 'video-player'; }

    static styles = [PlyrStyles, PlyrCustomStyles];
    static properties = {
        playerId: { type: String },
        videoid: { type: String, reflect: true },
        origin: { type: String },
        liked: { type: Boolean, reflect: true },
        saved: { type: Boolean, reflect: true },
        // state: { type: Number, state: true }
    };

    _fetchVideoInfoTask = new Task(this, {
        args: () => [this.videoid],
        task: async ([videoid], { signal }) => {
            const response = await fetch(`https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=${videoid}&format=json`, { signal });
            if (!response.ok)
                throw new Error(response.status);

            return await response.json();
        }
    });

    createRenderRoot() { return this; }

    renderDebugInfo() {
        return html`
            <span style="z-index: 999;display: flex;position: absolute;color: white;margin: 10px;font-size: 3em;top: 50%;background-color: black;">
                ${this.playerId}: ${this.state}
            </span>`
    }

    renderTitle() {
        return this._fetchVideoInfoTask.render({
            complete: (videoInfo) => html`
                <span>${videoInfo.title}</span>
                <span style="font-variant: all-small-caps;">${videoInfo.author_name}</span>
            `,
        });
    }

    render() {
        console.debug(`video-player: '${this.playerId}' render`);

        return html`
            <loading-animation></loading-animation>
            <div class="title-bar">
                <div class="card">
                    <bd-logo></bd-logo>
                    <div class="title-text">
                        ${this.renderTitle()}
                    </div>
                </div>
            </div>
            <div class="user-interactions">
                <heart-icon @click=${this.toggleLike}></heart-icon>
                <!-- <speech-bubble-icon></speech-bubble-icon> -->
                <paper-plane-icon @click=${this.share}></paper-plane-icon>
                <bookmark-icon @click=${this.save}></bookmark-icon>
            </div>
            <div class="plyr__video-embed" id="plyr__${this.playerId}">
                <iframe
                    src="https://www.youtube.com/embed/${this.videoid}?origin=${this.origin}&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
                    allowfullscreen
                    allowtransparency
                    allow="autoplay">
                </iframe>
            </div>
        `
    }

    async firstUpdated() {
        super.firstUpdated();

        this.loadingAnimation = this.renderRoot.querySelector(LoadingAnimation.is);

        this._plyr = new Plyr(`#plyr__${this.playerId} `, {
            autoplay: true,
            autopause: false,
            playsinline: true,
            clickToPlay: false,
            resetOnEnd: true,
            invertTime: false,
            hideControls: false,
            keyboard: { focused: false, global: false },
            controls: ['play-large', 'progress', 'current-time', 'settings'],
            fullscreen: { enabled: true, fallback: true, iosNative: false, container: "scroll-container" } // TODO: use lit context for container
        });

        this._plyr.once('ready', (e) => {
            const embed = e.detail.plyr.embed;
            embed.addEventListener("onError", this.handleVideoError);
            embed.addEventListener("onStateChange", (e) => {
                this.state = e.data;
                this.dispatchEvent(new CustomEvent(e.data, { e, bubbles: false, composed: false }));
            });

            this.hideControls();
            this._plyr.toggleCaptions(false);

            this.state = embed.getPlayerState();
            this.handleVideoLoad(this.state);

            this.dispatchEvent(new CustomEvent("playerready", {
                detail: { promise: Promise.resolve(this) }, bubbles: true, composed: true
            }));
        });
    }

    handleVideoError = () => {
        console.log(`video-player: '${this.playerId}' error`);
        this.classList.add("unavailable");
        this.dispatchEvent(new CustomEvent("videoready", {
            detail: { promise: Promise.reject(this) }, bubbles: true, composed: true
        }));
    }

    handleVideoEnable = () => {
        console.log(`video-player: '${this.playerId}' handleVideoEnable`);
        this.addEventListener("2", () => this._plyr.embed.unMute(), { once: true });
        this._plyr.embed.pauseVideo();
    }

    handleVideoLoad = (state) => {
        this.loadingAnimation.hide();

        console.log(`video-player: '${this.playerId}' handleVideoLoad: ${state}`);
        if (state === -1) { // loading error
            this.handleVideoError();
            return;
        }

        this.addEventListener("1", this.handleVideoAutoplay, { once: true });
        this._plyr.embed.mute();

        this.classList.add("unstarted");

        this.dispatchEvent(new CustomEvent("videoready", {
            detail: { promise: Promise.resolve(this) }, bubbles: true, composed: true
        }));
    }

    handleVideoAutoplay = () => {
        console.log(`video-player: '${this.playerId}' handleVideoAutoplay`);
        if (!this.playRequest) {
            this.addEventListener("2", () => {
                this._plyr.embed.unMute();
                this.addEventListener("1", () => { if (this.playRequest) this.handleVideoFirstPlay(); }, { once: true });

                this.requestUpdate();
            }, { once: true });
            this._plyr.embed.pauseVideo();
            return;
        }

        this.playRequest = false;
        this._plyr.embed.unMute();

        this.handleVideoFirstPlay();
    }

    handleVideoFirstPlay = () => {
        this.classList.remove("unstarted");
    }

    load(videoId, liked, saved) {
        console.debug(`video-player: '${this.playerId}' load: ` + videoId);

        this._plyr.once("statechange", (e) => this.handleVideoLoad(e.detail.code));
        this._plyr.embed.loadVideoById({ videoId: videoId, startSeconds: 0 });

        // const url = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
        // const fallBackUrl = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
        // this._plyr.poster = fallBackUrl;

        this.classList.remove(...this.classList);
        this.loadingAnimation.show();
        this.hideControls();

        // triggers render
        this.videoid = videoId;
        this.liked = liked;
        this.saved = saved;
    }

    enable(allowAutoplay) {
        if (!allowAutoplay && this.state === 2) { // 2 -> handleVideoAutoplay has already been triggered once
            this._plyr.embed.mute();
            this.addEventListener("1", this.handleVideoEnable, { once: true });
        }

        this.lastTouch = 0;
        this._plyr.on('touchstart', () => {
            this.touchPressed = false;
            this.touchTimer = setTimeout(() => {
                this.pause();
                this.showControls();
                this.touchPressed = true;

                if (this.controlsTimer !== undefined)
                    clearTimeout(this.controlsTimer);
            }, 150);
        });

        this._plyr.on('touchend', () => {
            const ticks = new Date().getTime();
            const touchDelta = ticks - this.lastTouch;
            this.lastTouch = ticks;

            if (this.touchPressed) {
                this.play();
                this.controlsTimer = setTimeout(() => this.hideControls(), 2000);
            }
            else if (touchDelta > 0 && touchDelta < 250) {
                this.like();
                this.showControls();
                this.controlsTimer = setTimeout(() => this.hideControls(), 2000);
            } else {
                this.toggleControls();
                if (this.controlsTimer !== undefined)
                    clearTimeout(this.controlsTimer);
            }

            this.touchPressed = false;
            clearTimeout(this.touchTimer);
            this.touchTimer = undefined;
        });

        this.playRequest = allowAutoplay;
        this._plyr.play();
    }

    play() {
        this.playRequest = true;
        this._plyr.play();
    }
    pause() {
        this.playRequest = false;
        this._plyr.pause();
    }
    stop() { this._plyr.stop(); }

    isPlaying() { return this._plyr.playing; }
    isPaused() { return this._plyr.paused; }
    isStopped() { return this._plyr.stopped; }

    forward() { this._plyr.forward(); }
    rewind() { this._plyr.rewind(); }

    toggleControls() {
        if (this.classList.contains("hide-controls"))
            this.showControls();
        else
            this.hideControls();
    }

    hideControls() {
        this.classList.add("hide-controls");
        this._plyr.toggleControls(false);
    }

    showControls() {
        this.classList.remove("hide-controls");
        this._plyr.toggleControls(true);
    }

    toggleLike = () => {
        this.liked = !this.liked;
        this.dispatchEvent(new CustomEvent("like", {
            detail: { id: this.videoid, state: this.liked }, bubbles: true, composed: true
        }));
    }

    like = () => {
        this.liked = true;
        this.dispatchEvent(new CustomEvent("like", {
            detail: { id: this.videoid, state: this.liked }, bubbles: true, composed: true
        }));
    }

    save() {
        this.saved = !this.saved;
        this.dispatchEvent(new CustomEvent("save", {
            detail: { id: this.videoid, state: this.saved }, bubbles: true, composed: true
        }));
    }

    share = async () => {
        const targetUrl = `${this.origin}/${this.videoid}`;

        if (navigator.vibrate)
            navigator.vibrate(50);

        if (!navigator.canShare) {
            prompt("💾 Copy following URL and share it with a friend!", targetUrl);
            return;
        }

        const shareData = {
            title: "💾 BLADING DATA",
            text: "Share the love!",
            url: targetUrl,
        };

        try {
            await navigator.share(shareData);
        }
        catch (err) {
            console.error(err);
        }
    }
}

customElements.define(VideoPlayer.is, VideoPlayer);