import { LitElement, html } from "lit";

export class HeartIcon extends LitElement {
    static get is() { return 'heart-icon'; }

    render() {
        return html`
            <svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke="inherit" fill="inherit">
                <path transform="translate(-1)" d="M11 4C5.5-1.5-1.5 5.5 4 11l7 7 7-7c5.458-5.458-1.542-12.458-7-7Z"/>
            </svg>
        `;
    }
}

customElements.define(HeartIcon.is, HeartIcon);

/***********************************************************************************************************/

export class SpeechBubbleIcon extends LitElement {
    static get is() { return 'speech-bubble-icon'; }

    render() {
        return html`
            <svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" stroke="inherit" fill="inherit">
                <path transform="scale(1, 1.1)" d="M5 5h9M5 9h5m8-8H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h4l3.5 4 3.5-4h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"/>
            </svg>
        `;
    }
}

customElements.define(SpeechBubbleIcon.is, SpeechBubbleIcon);

/***********************************************************************************************************/

export class PaperPlaneIcon extends LitElement {
    static get is() { return 'paper-plane-icon'; }

    render() {
        return html`
            <svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke="inherit" fill="inherit">
                <path transform="rotate(45, 9, 10) translate(1.5, -2) scale(.9)" d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"/>
            </svg>
        `;
    }
}

customElements.define(PaperPlaneIcon.is, PaperPlaneIcon);

/***********************************************************************************************************/

export class BookmarkIcon extends LitElement {
    static get is() { return 'bookmark-icon'; }

    render() {
        return html`
            <svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" stroke="inherit" fill="inherit">
                <path transform="translate(2.5)" d="m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z"/>
            </svg>
        `;
    }
}

customElements.define(BookmarkIcon.is, BookmarkIcon);