import { LitElement, html } from "lit";

import "./view/feed-view"

export default class AppShell extends LitElement {
    static get is() { return 'app-shell'; }

    constructor() {
        super();

        try {
            this.worker = new Worker(new URL('worker.js', import.meta.url));
            console.debug("Registered service worker.");
        } catch (error) {
            console.error("Error registering service worker: ", error);
            return;
        }
    }

    createRenderRoot() { return this; }

    render() { return html`<feed-view></feed-view>`; }
}

customElements.define(AppShell.is, AppShell);