import { LitElement, html, css } from "lit";

import "../logo";

export default class LoadingAnimation extends LitElement {
    static get is() { return 'loading-animation'; }

    static styles = css`
        :host {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 999999;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, .96);
            opacity: 1;
            transition: opacity 500ms ease-out;
        }

        :host(.hidden) {
            opacity: 0;
            pointer-events: none;
        }

        bd-logo {
            display: flex;
            fill: white;
            position: absolute;
            width: 40px;
            height: 40px;
            animation: logo-pulse .75s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }

        @keyframes logo-pulse {
            0% { transform: scale(1); }
            10% { transform: scale(.85); }
            100% { transform: scale(1); }
        }

        .ripple {
            position: absolute;
            width: 60px;
            height: 60px;
            box-sizing: border-box;
            border-radius: 50%;
            border: 3px solid white;
            filter: drop-shadow(0 0px 5px white);
            animation: ripple-pulse .75s ease-out infinite;
        }

        @keyframes ripple-pulse {
            0% {
                transform: scale(0.5);
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100% {
                transform: scale(1.1);
                opacity: 0;
            }
        }

        ::slotted(*) {
            color: white;
            font-size: 12.5px;
            font-family: Montserrat;
            margin-top: 150px;
            opacity: 0.9;
        }
    `;

    render() {
        return html`
            <bd-logo></bd-logo>
            <div class="ripple"></div>
            <slot name="text"></slot>
        `;
    }

    hide() { this.classList.add("hidden"); }
    show() { this.classList.remove("hidden"); }
}

customElements.define(LoadingAnimation.is, LoadingAnimation);
