import { LitElement, html, nothing } from "lit";
import { repeat } from 'lit/directives/repeat.js';
import { when } from 'lit/directives/when.js';

import ScrollContainer from "../scroll-container";
import LoadingAnimation from "./loading-animation";
import VideoPlayer from "./video-player";
import { HeartIcon, BookmarkIcon } from "../icons.js";

import "swiped-events/src/swiped-events"

import { DATA } from '../data.js';

export default class FeedView extends LitElement {
    static get is() { return 'feed-view'; }

    static isMobile() {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
    }

    static requestFullscreen(element) {
        if (element.requestFullscreen)
            element.requestFullscreen();
        else if (element.webkitRequestFullscreen) /* Safari */
            element.webkitRequestFullscreen();
        else if (element.msRequestFullscreen) /* IE11 */
            element.msRequestFullscreen();
        else
            console.warn("Couldn't request fullscreen");
    }

    static random(seed) {
        const x = Math.sin(seed) * 10000;
        return x - Math.floor(x);
    }

    static swap(array, i, j) { [array[i], array[j]] = [array[j], array[i]]; };

    static shuffleInPlace(array, seed) {
        for (let i = array.length - 1; i > 0; i--) {
            // Generate a random index between 0 and i (inclusive)
            const j = Math.floor(FeedView.random(seed++) * (i + 1));
            FeedView.swap(array, i, j);
        }
    };

    getNextEntry() {
        const nextValue = this.iterator.next();
        if (nextValue.done) {
            alert("YOU WATCHED EVERYTHING!");
            return;
        }

        return nextValue.value;
    }

    saveUserInfo() {
        localStorage.setItem('bladingdata', JSON.stringify(this.info));
    }

    constructor() {
        super();

        if (!localStorage.getItem('bladingdata')) {
            console.debug("localStorage init");
            this.info = { seed: performance.now() };
            localStorage.setItem('bladingdata', JSON.stringify(this.info));

            this.firstVisit = true;
        }
        else
            this.info = JSON.parse(localStorage.getItem('bladingdata'));

        if (!this.info.likes)
            this.info.likes = [];

        if (!this.info.saves)
            this.info.saves = [];

        FeedView.shuffleInPlace(DATA, this.info.seed); // TODO: move to service worker ?

        this.url = new URL(window.location.href);
        let param = this.url.pathname;
        if (param.startsWith("/"))
            param = param.substring(1);

        if (param.length === 11) {
            const targetIndex = DATA.indexOf(param);
            if (targetIndex === -1)
                DATA.unshift(param);
            else if (targetIndex > 0) // target index could be already 0
                FeedView.swap(DATA, 0, targetIndex);

            this.param = param;
        }

        this.playlist = DATA;
        this.iterator = this.playlist[Symbol.iterator](); // TODO: loop
        this.isMobile = FeedView.isMobile();
        this.firstInteraction = false;
        this.sectionCount = 4;
        this.sections = [];

        console.debug(this.info);

        if (this.info.lastWatched === undefined)
            return;

        function moveToIndex(iterator, targetIndex) {
            let currentIndex = 0;
            let nextValue = iterator.next();

            while (!nextValue.done && currentIndex < targetIndex) {
                nextValue = iterator.next();
                currentIndex++;
            }

            return nextValue.value;
        }

        const lastIndex = this.playlist.indexOf(this.info.lastWatched); // TODO: get from shuffle to avoid iterating twice
        moveToIndex(this.iterator, lastIndex - 1); // step one back so when first section is created and increments iterator
    }

    createRenderRoot() { return this; }

    render() {
        console.debug("feed-view: render");
        return html`
            <loading-animation>
                ${when(this.firstVisit,
            () => html`<span slot="text">Initializing...</span>`,
            () => html`<span slot="text">Welcome back 💾</span>`)}
            </loading-animation>
            <scroll-container
                @sectionleave=${this.onSectionLeave} @sectionenter=${this.onSectionEnter}
                data-swipe-unit="px" data-swipe-timeout="250" data-swipe-threshold="10">
                    ${repeat(this.sections, (section) => section.id, (section) => section)}
            </scroll-container>
            <heart-icon class="ephemeral"></heart-icon>
            <bookmark-icon class="ephemeral"></bookmark-icon>
        `;
    }

    renderVideoPlayer(playerId, videoId) { // TODO: make static for consistency
        return html`
            <video-player
                playerid="${playerId}"
                videoid="${videoId}"
                origin=${this.url.origin}
                liked=${this.info.likes.includes(videoId) || nothing}
                saved=${this.info.saves.includes(videoId) || nothing}>
            </video-player>
        `;
    }

    async firstUpdated() {
        super.firstUpdated();

        this.scrollContainer = this.renderRoot.querySelector(ScrollContainer.is);
        this.likeLogo = this.renderRoot.querySelector(HeartIcon.is);
        this.saveLogo = this.renderRoot.querySelector(BookmarkIcon.is);

        let promises = [];
        for (var i = 0; i < this.sectionCount; i++) {
            const playerId = `p${i}`;
            const videoId = this.getNextEntry();
            const playerTemplate = this.renderVideoPlayer(playerId, videoId);

            const section = this.scrollContainer.createSection(playerTemplate);
            section.id = `s${i}`;
            section.addEventListener('swiped-up', () => this.scrollContainer.scrollToSection(section, 1));
            section.addEventListener('swiped-down', () => this.scrollContainer.scrollToSection(section, -1));

            const player = section.querySelector(VideoPlayer.is);
            player.addEventListener('ended', () => this.scrollContainer.scrollToSection(section, 1));

            promises.push(new Promise((resolve) => {
                player.addEventListener('playerready', () => resolve());
            }))

            this.sections.push(section);
        }

        this.addEventListener("videoready", this.onVideoReady);

        this.requestUpdate();
        await Promise.all(promises);

        const loading = this.renderRoot.querySelector(LoadingAnimation.is);
        loading.hide();

        window.addEventListener("keydown", (e) => {
            if (e.code !== "ArrowUp" && e.code !== "ArrowDown")
                return;

            if (e.repeat)
                return;

            const direction = e.code === "ArrowDown" ? 1 : -1;
            this.scrollContainer.scrollToNextSection(direction);
        }, true);

        window.addEventListener("keydown", (e) => {
            if (e.code !== "ArrowLeft" && e.code !== "ArrowRight")
                return;

            if (e.repeat)
                return;

            const section = this.scrollContainer.activeSection;
            const player = section.querySelector(VideoPlayer.is);
            if (player === undefined || player === null)
                return;

            if (e.code === "ArrowLeft")
                player.rewind();
            else
                player.forward();
        }, true);

        window.addEventListener("keydown", (e) => {
            if (e.code !== "Space")
                return;

            if (e.repeat)
                return;

            const section = this.scrollContainer.activeSection;
            const player = section.querySelector(VideoPlayer.is);
            if (player === undefined || player === null)
                return;

            if (player.isPlaying())
                player.pause();
            else
                player.play();
        }, true);

        this.addEventListener("click", this.onFirstUserInteraction, { once: true, capture: true });

        this.addEventListener("like", (e) => this.onUserInteraction("like", e.detail.id, e.detail.state, this.info.likes, this.likeLogo));
        this.addEventListener("save", (e) => this.onUserInteraction("save", e.detail.id, e.detail.state, this.info.saves, this.saveLogo));
    }

    onFirstUserInteraction(e) {
        if (this.firstInteraction)
            return;

        e.preventDefault();
        e.stopPropagation(); // avoid triggering player clickToPlay

        this.firstInteraction = true
        this.sections.forEach((section) => {
            const allowAutoplay = section.id === this.scrollContainer.activeSection.id;
            const player = section.querySelector(VideoPlayer.is);
            player.enable(allowAutoplay);
        });
    }

    onUserInteraction(action, id, state, collection, element) {
        console.debug(`${action}: `, id);

        if (!state) {
            const index = collection.indexOf(id);
            if (index > -1)
                collection.splice(index, 1);
        }
        else if (!collection.includes(id)) { // TODO: avoid includes
            collection.push(id);
        }

        this.saveUserInfo();

        if (!state)
            return;

        if (navigator.vibrate)
            navigator.vibrate(50);

        element.addEventListener("animationend", () => element.classList.remove("rise"), { once: true });
        element.classList.add("rise");
    }

    onVideoReady(e) {
        e.detail.promise.catch(
            (player) => {
                if (player.videoid !== this.param || player.videoid !== this.playlist[0]) {
                    player.load(this.getNextEntry());
                    return;
                }

                // this.scrollContainer.timeout = setTimeout(() => {
                //     this.scrollContainer.timeout = undefined;
                //     this.scrollContainer.scrollToNextSection(1);
                // }, 10000);
            });
    }

    onSectionLeave(e) {
        const direction = e.detail.direction;
        if (direction > 0) {
            const prevSection = e.detail.current;
            const prevPlayer = prevSection.querySelector(VideoPlayer.is);
            if (prevPlayer !== undefined && prevPlayer !== null) {
                this.info.lastWatched = prevPlayer.videoid;
                localStorage.setItem('bladingdata', JSON.stringify(this.info));
            }
        }

        if (!this.firstInteraction)
            return;

        const nextSection = e.detail.target;
        const nextPlayer = nextSection.querySelector(VideoPlayer.is);
        if (nextPlayer !== undefined && nextPlayer !== null)
            nextPlayer.play();

        const prevSection = e.detail.current;
        const prevPlayer = prevSection.querySelector(VideoPlayer.is);
        if (prevPlayer !== undefined && prevPlayer !== null)
            prevPlayer.pause();
    }

    onSectionEnter(e) {
        const section = e.detail.target;
        const direction = e.detail.direction;

        const player = section.querySelector(VideoPlayer.is);
        if (player !== undefined && player !== null)
            window.history.replaceState(null, null, player.videoid);

        if (direction > 0 && Number(section.style.zIndex) % this.sectionCount - 1 === 0) {
            const firstSection = this.scrollContainer.getFirstSection();
            const firstPlayer = firstSection.querySelector(VideoPlayer.is);

            const videoId = this.getNextEntry();
            const liked = this.info.likes.includes(videoId);
            const saved = this.info.saves.includes(videoId);
            firstPlayer.load(videoId, liked, saved);

            this.scrollContainer.shift();
        }
    }
}

customElements.define(FeedView.is, FeedView);

/*
let touchstartX = 0;
let touchstartY = 0;
let touchendX = 0;
let touchendY = 0;

const handleGesture = (touchstartX, touchstartY, touchendX, touchendY) => {
    const delx = touchendX - touchstartX;
    const dely = touchendY - touchstartY;
    if (Math.abs(delx) > Math.abs(dely)) {
        if (delx > 0) return "right"
        else return "left"
    }
    else if (Math.abs(delx) < Math.abs(dely)) {
        if (dely > 0) return "down"
        else return "up"
    }
    else return "tap"
}

this.scrollContainer.addEventListener('touchstart', (event) => {
    touchstartX = event.changedTouches[0].screenX;
    touchstartY = event.changedTouches[0].screenY;
}, false);

this.scrollContainer.addEventListener('touchend', (event) => {
    touchendX = event.changedTouches[0].screenX;
    touchendY = event.changedTouches[0].screenY;
    const gesture = handleGesture(touchstartX, touchstartY, touchendX, touchendY);

    if (gesture !== "up" && gesture !== "down")
        return;

    let direction;
    if (gesture === "up")
        direction = 1;
    else if (gesture === "down")
        direction = -1;

    const currSection = this.scrollContainer.activeSection;
    const nextSection = ScrollContainer.getNextSection(currSection, direction);

    const currPlayer = currSection.querySelector(VideoPlayer.is);
    const nextPlayer = nextSection.querySelector(VideoPlayer.is);

    currPlayer.pause();
    nextPlayer.play();

    this.scrollContainer.scrollToSection(currSection, direction);
}, false);
*/