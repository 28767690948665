import { LitElement, html, css, svg } from "lit";

export default class Logo extends LitElement {
    static get is() { return 'bd-logo'; }

    static styles = css`
        :host {
        }

        svg {
            transform: scale(.75);
        }
    `;

    static logo = svg`
            <path d="M59.79,122.16c-1.85-3.99-4.11-7.35-6.78-10.06c-2.67-2.71-5.77-4.77-9.3-6.18c-3.53-1.41-7.46-2.11-11.79-2.11
                c-6.56,0-12.2,1.59-16.94,4.76c-4.73,3.18-8.34,7.79-10.82,13.84c-1.43,3.66-2.48,8.03-3.15,13.12C0.34,140.62,0,146.34,0,152.68
                c0,4.92,0.3,9.49,0.91,13.72c0.61,4.23,1.57,8.08,2.87,11.57c2.56,6.39,6.38,11.4,11.45,15.01c5.07,3.62,10.8,5.42,17.19,5.42
                c5.55,0,10.6-1.35,15.14-4.04c4.54-2.69,8.24-6.52,11.1-11.48c2.27-4.04,3.83-8.54,4.67-13.5c0.84-4.96,1.26-10.89,1.26-17.79
                C64.59,138.91,62.99,129.1,59.79,122.16L59.79,122.16z M45.98,170.03c-0.88,5-2.42,8.85-4.6,11.54c-2.19,2.69-5.21,4.04-9.08,4.04
                c-3.74,0-6.71-1.29-8.89-3.88c-2.19-2.59-3.74-6.4-4.67-11.45c-0.93-5.05-1.39-11.52-1.39-19.43c0-11.65,1.08-20.27,3.25-25.86
                c2.17-5.59,6.02-8.39,11.57-8.39c3.87,0,6.9,1.25,9.08,3.75c2.19,2.5,3.74,6.2,4.67,11.1c0.92,4.9,1.39,11.2,1.39,18.89
                C47.3,158.47,46.86,165.03,45.98,170.03z"/>
            <path d="M136.55,122.16c-1.85-3.99-4.11-7.35-6.78-10.06c-2.67-2.71-5.77-4.77-9.3-6.18c-3.53-1.41-7.46-2.11-11.79-2.11
                c-6.56,0-12.2,1.59-16.94,4.76c-4.73,3.18-8.34,7.79-10.82,13.84c-1.43,3.66-2.48,8.03-3.15,13.12c-0.67,5.09-1.01,10.81-1.01,17.16
                c0,4.92,0.3,9.49,0.91,13.72c0.61,4.23,1.57,8.08,2.87,11.57c2.56,6.39,6.38,11.4,11.45,15.01c5.07,3.62,10.8,5.42,17.19,5.42
                c5.55,0,10.6-1.35,15.14-4.04c4.54-2.69,8.24-6.52,11.1-11.48c2.27-4.04,3.83-8.54,4.67-13.5c0.84-4.96,1.26-10.89,1.26-17.79
                C141.35,138.91,139.75,129.1,136.55,122.16L136.55,122.16z M122.74,170.03c-0.88,5-2.42,8.85-4.6,11.54
                c-2.19,2.69-5.21,4.04-9.08,4.04c-3.74,0-6.71-1.29-8.89-3.88c-2.19-2.59-3.74-6.4-4.67-11.45c-0.93-5.05-1.39-11.52-1.39-19.43
                c0-11.65,1.08-20.27,3.25-25.86c2.17-5.59,6.02-8.39,11.57-8.39c3.87,0,6.9,1.25,9.08,3.75c2.19,2.5,3.74,6.2,4.67,11.1
                c0.92,4.9,1.39,11.2,1.39,18.89C124.06,158.47,123.62,165.03,122.74,170.03z"/>
            <path d="M213.31,122.16c-1.85-3.99-4.11-7.35-6.78-10.06c-2.67-2.71-5.77-4.77-9.3-6.18c-3.53-1.41-7.46-2.11-11.79-2.11
                c-6.56,0-12.2,1.59-16.94,4.76c-4.73,3.18-8.34,7.79-10.82,13.84c-1.43,3.66-2.48,8.03-3.15,13.12c-0.67,5.09-1.01,10.81-1.01,17.16
                c0,4.92,0.3,9.49,0.91,13.72c0.61,4.23,1.57,8.08,2.87,11.57c2.56,6.39,6.38,11.4,11.45,15.01c5.07,3.62,10.8,5.42,17.19,5.42
                c5.55,0,10.6-1.35,15.14-4.04c4.54-2.69,8.24-6.52,11.1-11.48c2.27-4.04,3.83-8.54,4.67-13.5c0.84-4.96,1.26-10.89,1.26-17.79
                C218.11,138.91,216.51,129.1,213.31,122.16L213.31,122.16z M199.5,170.03c-0.88,5-2.42,8.85-4.6,11.54
                c-2.19,2.69-5.21,4.04-9.08,4.04c-3.74,0-6.71-1.29-8.89-3.88c-2.19-2.59-3.74-6.4-4.67-11.45c-0.93-5.05-1.39-11.52-1.39-19.43
                c0-11.65,1.08-20.27,3.25-25.86c2.17-5.59,6.02-8.39,11.57-8.39c3.87,0,6.9,1.25,9.08,3.75c2.19,2.5,3.74,6.2,4.67,11.1
                c0.92,4.9,1.39,11.2,1.39,18.89C200.82,158.47,200.38,165.03,199.5,170.03z"/>
            <path d="M290.07,122.16c-1.85-3.99-4.11-7.35-6.78-10.06c-2.67-2.71-5.77-4.77-9.3-6.18c-3.53-1.41-7.46-2.11-11.79-2.11
                c-6.56,0-12.2,1.59-16.94,4.76c-4.73,3.18-8.34,7.79-10.82,13.84c-1.43,3.66-2.48,8.03-3.15,13.12c-0.67,5.09-1.01,10.81-1.01,17.16
                c0,4.92,0.3,9.49,0.91,13.72c0.61,4.23,1.57,8.08,2.87,11.57c2.56,6.39,6.38,11.4,11.45,15.01c5.07,3.62,10.8,5.42,17.19,5.42
                c5.55,0,10.6-1.35,15.14-4.04c4.54-2.69,8.24-6.52,11.1-11.48c2.27-4.04,3.83-8.54,4.67-13.5c0.84-4.96,1.26-10.89,1.26-17.79
                C294.87,138.91,293.27,129.1,290.07,122.16L290.07,122.16z M276.26,170.03c-0.88,5-2.42,8.85-4.6,11.54
                c-2.19,2.69-5.21,4.04-9.08,4.04c-3.74,0-6.71-1.29-8.89-3.88c-2.19-2.59-3.74-6.4-4.67-11.45c-0.93-5.05-1.39-11.52-1.39-19.43
                c0-11.65,1.08-20.27,3.25-25.86c2.17-5.59,6.02-8.39,11.57-8.39c3.87,0,6.9,1.25,9.08,3.75c2.19,2.5,3.74,6.2,4.67,11.1
                c0.92,4.9,1.39,11.2,1.39,18.89C277.58,158.47,277.14,165.03,276.26,170.03z"/>
            <path d="M60.67,61.49c-3.46,3.12-5.19,7.25-5.19,12.4c0,11.56,8.48,17.33,25.43,17.33h144.87c6.07,0,10.84-1.35,14.3-4.05
                c3.46-2.7,5.19-6.28,5.19-10.75c0-3.97-0.76-6.66-2.28-8.1c-1.52-1.44-5.57-4.51-12.15-9.24c-6.58-4.72-12.53-10.14-17.84-16.26
                c-5.31-6.12-10.38-14.28-15.18-24.48c-3.21-6.83-5.82-11.6-7.84-14.3c-2.02-2.7-5.19-4.05-9.49-4.05c-3.71,0-6.98,1.37-9.81,4.11
                c-2.83,2.74-4.24,5.88-4.24,9.43c0,7.42,8.22,21.85,24.67,43.27H75.98C69.23,56.81,64.13,58.37,60.67,61.49z"/>
        `;

    render() {
        return html`
            <svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
	              x="0px" y="0px" viewBox="0 0 294.87 198.41">
                ${Logo.logo}
            </svg>
        `;
    }
}

customElements.define(Logo.is, Logo);
